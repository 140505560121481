"use client";

import { Card, CardContent } from "@/components/shared/ui/card";
import { SUPPLIER_FILTER_ID } from "@/lib/shop/shop-constants";
import { ShopMenuPromotion } from "@/lib/shop/shop-types";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";

type MenuPromotionCarouselProps = {
    menuPromotions: ShopMenuPromotion[];
    showTitle?: boolean;
};

const MenuPromotionCarousel = ({
    menuPromotions,
    showTitle = true
}: MenuPromotionCarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prev) => (prev + 1) % menuPromotions.length);
        }, 15000);
        return () => clearInterval(timer);
    }, [menuPromotions.length]);

    if (menuPromotions.length === 0) {
        return null;
    }

    const navigate = (direction: "prev" | "next") => {
        if (direction === "prev") {
            setCurrentIndex((prev) => (prev - 1 + menuPromotions.length) % menuPromotions.length);
        } else {
            setCurrentIndex((prev) => (prev + 1) % menuPromotions.length);
        }
    };

    const currentPromotion = menuPromotions[currentIndex];

    if (!currentPromotion) {
        return null;
    }

    const handleClick = (e: React.MouseEvent, direction: "prev" | "next") => {
        e.preventDefault();
        e.stopPropagation();
        navigate(direction);
    };

    return (
        <div className="relative mx-auto w-full max-w-4xl">
            <Link
                href={`/${currentPromotion.state}/shop?${SUPPLIER_FILTER_ID}=${currentPromotion.supplierID}`}
                className="block"
            >
                <Card className="md:h-35 h-40 rounded-lg bg-white transition-colors hover:bg-gray-50">
                    <CardContent className="h-full p-6">
                        <div className="flex h-full items-center justify-between">
                            {menuPromotions.length > 1 ? (
                                <button
                                    onClick={(e) => handleClick(e, "prev")}
                                    className="rounded-full p-2 hover:bg-gray-100"
                                    aria-label="Previous promotion"
                                >
                                    <ChevronLeft className="h-6 w-6" />
                                </button>
                            ) : null}

                            <div className="mx-2 flex h-full flex-col items-center space-x-6 md:flex-row md:items-start">
                                {currentPromotion.supplierLogoURL !== undefined && showTitle ? (
                                    <div className="flex-shrink-0">
                                        <Image
                                            src={currentPromotion.supplierLogoURL}
                                            alt={currentPromotion.supplierName}
                                            width={100}
                                            height={100}
                                            className="h-16 w-16 object-contain"
                                        />
                                    </div>
                                ) : showTitle ? (
                                    <h3 className="mb-2 text-xl font-bold text-gray-900">
                                        {currentPromotion.supplierName}
                                    </h3>
                                ) : null}

                                <div className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent h-full flex-1 overflow-y-auto">
                                    <p
                                        className="overflow-y-hidden text-[length:var(--dynamic-text-size)] text-gray-600 transition-[font-size] duration-200"
                                        style={{
                                            ["--dynamic-text-size" as string]:
                                                "clamp(0.75rem, 1rem, 1.25rem)"
                                        }}
                                    >
                                        {currentPromotion.description}
                                    </p>
                                    <p className="mt-2 text-sm text-gray-500">
                                        Ends:{" "}
                                        {new Date(currentPromotion.endDate).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                            {menuPromotions.length > 1 ? (
                                <button
                                    onClick={(e) => handleClick(e, "next")}
                                    className="rounded-full p-2 hover:bg-gray-100"
                                    aria-label="Next promotion"
                                >
                                    <ChevronRight className="h-6 w-6" />
                                </button>
                            ) : null}
                        </div>
                    </CardContent>
                </Card>
            </Link>
            {menuPromotions.length > 1 ? (
                <div className="mt-4 flex justify-center gap-2">
                    {menuPromotions.map((_, idx) => (
                        <button
                            key={idx}
                            className={`h-2 w-2 rounded-full ${
                                idx === currentIndex ? "bg-blue-600" : "bg-gray-300"
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentIndex(idx);
                            }}
                            aria-label={`Go to promotion ${idx + 1}`}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
export default MenuPromotionCarousel;

export const MenuPromotionCarouselSkeleton = () => {
    return (
        <div className=" relative mx-auto w-full">
            <Card className="bg-white">
                <CardContent className="p-6">
                    <div className="flex items-center justify-between">
                        <button
                            disabled
                            className="rounded-full p-2 opacity-50"
                            aria-label="Previous promotion"
                        >
                            <ChevronLeft className="h-6 w-6" />
                        </button>

                        <div className="mx-4 flex flex-col items-center space-x-6 md:flex-row md:items-start">
                            {/* Logo skeleton */}
                            <div className="flex-shrink-0">
                                <div className="h-24 w-24 animate-pulse rounded-md bg-gray-200" />
                            </div>

                            <div className="flex-1 space-y-3">
                                {/* Description skeleton - longer heading style line */}
                                <div className="space-y-2">
                                    <div className="h-8 w-full max-w-2xl animate-pulse rounded bg-gray-200" />
                                    <div className="h-8 w-3/4 animate-pulse rounded bg-gray-200" />
                                </div>

                                {/* Date skeleton */}
                                <div className="h-5 w-48 animate-pulse rounded bg-gray-200" />
                            </div>
                        </div>

                        <button
                            disabled
                            className="rounded-full p-2 opacity-50"
                            aria-label="Next promotion"
                        >
                            <ChevronRight className="h-6 w-6" />
                        </button>
                    </div>
                </CardContent>
            </Card>

            {/* Dots skeleton */}
            <div className="mt-4 flex justify-center gap-2">
                {[...Array(3)].map((_, idx) => (
                    <div key={idx} className="h-2 w-2 animate-pulse rounded-full bg-gray-200" />
                ))}
            </div>
        </div>
    );
};
